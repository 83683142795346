// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerPage-serverList--mi1J5{\n  margin-top: 10px;\n  display: inline-flex;\n}\n\n.ServerPage-uploadWrapper--Hk5tt{\n  display: inline-grid;\n}\n\n.ServerPage-formcontainer--tQXpr{\n  display: grid;\n}\n\n.ServerPage-uploadb--BJjBB{\ndisplay: none;\n}\n\n\nbutton {\n  background-color: #555555 !important; \n  border: none !important;\n  color: white !important;\n  padding: 15px 32px !important;\n  text-align: center !important;\n  text-decoration: none !important;\n  width: 10vw !important;\n  height: 5vh !important;\n  display: inline-block;\n  font-size: 1em !important;\n}\n", "",{"version":3,"sources":["webpack://./client/components/pages/ServerPage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;AACA,aAAa;AACb;;;AAGA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,uBAAuB;EACvB,6BAA6B;EAC7B,6BAA6B;EAC7B,gCAAgC;EAChC,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;EACrB,yBAAyB;AAC3B","sourcesContent":[".serverList{\n  margin-top: 10px;\n  display: inline-flex;\n}\n\n.uploadWrapper{\n  display: inline-grid;\n}\n\n.formcontainer{\n  display: grid;\n}\n\n.uploadb{\ndisplay: none;\n}\n\n\nbutton {\n  background-color: #555555 !important; \n  border: none !important;\n  color: white !important;\n  padding: 15px 32px !important;\n  text-align: center !important;\n  text-decoration: none !important;\n  width: 10vw !important;\n  height: 5vh !important;\n  display: inline-block;\n  font-size: 1em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serverList": "ServerPage-serverList--mi1J5",
	"uploadWrapper": "ServerPage-uploadWrapper--Hk5tt",
	"formcontainer": "ServerPage-formcontainer--tQXpr",
	"uploadb": "ServerPage-uploadb--BJjBB"
};
export default ___CSS_LOADER_EXPORT___;
