import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import * as R from 'ramda';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';

import styles from './styles.module.css';

export default function HomePage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, [dispatch, user]);

  return (
    <div className={styles.root}>
      <Section>
        <Container>
          <Title size="1" className="is-4">
            Thank You for signing up!
          </Title>
          <p>
            We don't have much to advertise but we will in the future. Go head on over to the server page to change out games!
          </p>
        </Container>
      </Section>
    </div>
  );
}
