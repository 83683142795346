import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';

export const putServer = info =>
  request.put('/api/servers/changeServer')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const getServer = info =>
  request.get('/api/servers/getServer')
    .then(handleSuccess)
    .catch(handleError);

export const getServerFiles = async () =>
  request.get('/api/servers/getServerFiles')
    .then(handleSuccess)
    .catch(handleError);

export const stopServer = async ( info ) =>
  request.put('/api/servers/stopServer')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const startServer = async ( info ) =>
  request.put('/api/servers/startServer')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);
