import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { putServer, getServer, getServerFiles, stopServer, startServer } from '_api/server';
import * as R from 'ramda';

import Section from 'react-bulma-companion/lib/Section';
import Button from 'react-bulma-companion/lib/Button';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Control from 'react-bulma-companion/lib/Control';

import { attemptGetTodos } from '_store/thunks/todos';

import styles from './styles.module.css';

export default function ServerPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [loading, setLoading] = useState(true);
  const [server, setServer] = useState('');
  const [status, setStatus] = useState('disabled');
  const [stat, setStat] = useState(true);
  const [start, setStart] = useState(true);
  const [username, setusername] = useState('');
  const [servername, setservername] = useState('');
  const [tStatus, settStatus] = useState({running: user.running, starting: user.starting});
  
  const serverList = ['Terraria', 'Minecraft', '7DaysToDie', 'Valheim', 'VRising'];
/*
  const getCurrentServer = async () => {
    const {server} = await getServer();
    setServer(server);
    return server;
  };


  const getFiles = async () => {
    const output = await getServerFiles();
    return output.body;
  };
*/
  const updateServerInfo = (serverName, inusername) => {
    setservername(serverName);
    setusername(inusername);
    setStart(false);
  };
  const updateServer = (name, user) => {
    const serverUpdateInfo = {server: name, username: user};
    setServer(name);
    setStart(true);
    putServer(serverUpdateInfo);
  };

  const checkServerStatus = () => {
    setServer(user.serverName);
    if(user.running && !user.starting){
      setStatus('Running');
    }
    if(user.starting){
      setStatus('Starting');
    }
  }
  
  const port = (user.user * 10) + 5000;

  //useEffect(() => {
  //  checkServerStatus();
  //}, [user]);

  useEffect(() => {
    checkServerStatus();
  }, []);
  
  useEffect(() => {
    user.serverName=server;
  }, [server]);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    } else {
      setLoading(false);
    }
  }, [dispatch, user]);


  return !loading && (
    <div className="blok">
      <Section className="blok-box">
        <Title size="1" className="has-text-centered blok">
          {`Current Server: ${server}`}
        </Title>
        <Title className="has-text-centered blok">
          {`Current Server Status: ${status}`}
        </Title>
        <Title className="has-text-centered urlGrid">
          <p className="urlStyle">
          {`Your server URL/IP:`}
          </p>
          <p className="portStyle">
          {`www.monocomputing.com:${port}`}
          </p>
        </Title>
        <Columns>
          <Column size="8" offset="2" className="has-text-centered">
            <Title className="has-text-centered blok">
		Server Management
            </Title>
          </Column>
        </Columns>
            <div className="inlineCol">
              <form className="topButton" action="/api/servers/getServerFiles" method="get">
                <Button /*disabled={true}*/ type="submit">
                  Download Running
                </Button>
              </form>
              <div style={{margin: '0 0 0 1em'}}>
                <form className="formcontainer" style={{display: 'grid'}} enctype='multipart/form-data' action="/api/servers/postServerFiles" method="post">
                  <Button disabled={stat} type="submit">
                    Upload
                  </Button>
                  <label for="file-upload" className="button" type="file">
                    Select Files
                    <input id="file-upload" type="file" name="fileup" className="uploadb" onChange={()=>{setStat(false)}} />
                  </label>
                </form>
              </div>
                <Button className="topButton" disabled={start} onClick={ ()=>{updateServer(servername, username)} } type="submit">
                  { start ? "Start Server" : `Start ${servername}` }
                </Button>
                <Button className="topButton" onClick={ () => {stopServer()} } type="submit">
                  Stop Server
                </Button>
            </div>
        <Columns>
          <Column size="8" offset="2" className="has-text-left">
		Available Server's
            <Control>
              <div className="inlineCol">
              <ul>
                { serverList.map((serverName, i) => {
                  return(
                    <li key={i} className={styles.serverList}>
                        <Button onClick={ ()=>{updateServerInfo(serverName, user.username)} }>
                          {serverName}
                        </Button>
                    </li>
                  )
                  }) 
                }
              </ul>
            </div>
            </Control>
          </Column>
        </Columns>
      </Section>
    </div>
  );
}
                        //<input type="radio" name="srvs" style={{marginLeft: "1vw"}} value={serverName} />
                      //<div style={{display:"flex", alignSelf: "center", marginLeft: "1vw"}}>User has Game files on account</div>
