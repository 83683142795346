import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';

import Box from 'react-bulma-companion/lib/Box';
import Button from 'react-bulma-companion/lib/Button';
import Title from 'react-bulma-companion/lib/Title';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Icon from 'react-bulma-companion/lib/Icon';
import Input from 'react-bulma-companion/lib/Input';
import Label from 'react-bulma-companion/lib/Label';
import Help from 'react-bulma-companion/lib/Help';

import useKeyPress from '_hooks/useKeyPress';
import { postCheckUsername } from '_api/users';
import { postCheckEmail } from '_api/email';
import { checkSlotsAvailable } from '_api/auth';
import { validateUsername, validatePassword, validateEmail } from '_utils/validation';
import { attemptRegister } from '_store/thunks/auth';

import styles from './styles.module.css';

export default function Register() {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [usernameMessage, setUsernameMessage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [slotsAvailable, setSlotsAvailable] = useState(false);

  useEffect(() =>{
    checkSlots();
  },[slotsAvailable]);

  const checkPassword = (newUsername, newPassword) => {
    const { valid, message } = validatePassword(newUsername, newPassword);

    setPasswordValid(valid);
    setPasswordMessage(message);
  };
  const checkSlots = async () => {
    const {message, canRegister} = await checkSlotsAvailable();
    console.log(`Message: ${message}, Available: ${canRegister}`);
    setSlotsAvailable(canRegister);
  };
  const checkEmail = newEmail => {
    const { valid, message } = validateEmail(newEmail);

    if (valid) {
      setEmailMessage('Checking Email...');
      setEmailAvailable(false);

      postCheckEmail(newEmail)
        .then(res => {
          setEmailAvailable(res.available);
          setEmailMessage(res.message);
        })
        .catch(R.identity);
    } else {
      setEmailAvailable(valid);
      setEmailMessage(message);
    }
  };

  const checkUsername = newUsername => {
    const { valid, message } = validateUsername(newUsername);

    if (valid) {
      setUsernameMessage('Checking username...');
      setUsernameAvailable(false);

      postCheckUsername(newUsername)
        .then(res => {
          setUsernameAvailable(res.available);
          setUsernameMessage(res.message);
        })
        .catch(R.identity);
    } else {
      setUsernameAvailable(valid);
      setUsernameMessage(message);
    }
  };
  
  const updateEmail = newEmail => {
    setEmail(newEmail);
  };

  const updateUsername = newUserName => {
    setUsername(newUserName);
    checkPassword(newUserName, password);
  };

  const handleEmailChange = e => {
    updateEmail(e.target.value);
    checkEmail(e.target.value);
  };

  const handleUsernameChange = e => {
    updateUsername(e.target.value);
    checkUsername(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    checkPassword(username, e.target.value);
  };

  const registerButton = () => {
    const registerText = "Create Account";
    const unavailableText = "Servers Maxxed";
    checkSlots();
    if(slotsAvailable){
      return(registerText);
    }
    else{
      return(unavailableText);
    }
  };

  const register = () => {
    if (usernameAvailable && passwordValid) {
      const newUser = {
        username,
        password,
        email,
      };

      dispatch(attemptRegister(newUser))
        .catch(R.identity);
    }
  };

  useKeyPress('Enter', register);

  return (
    <Box className={styles.root}>
      <Title size="3">
        Sign Up
      </Title>
      <hr className="separator" />
      <p className="has-space-below preAtag">
        Already a member?&nbsp;
        <Link to="/login">
          Login
        </Link>
      </p>
      <Field>
        <Label htmlFor="username">
          Username
        </Label>
        <Control iconsRight>
          <Input
            id="username"
            placeholder="Username"
            color={username ? (usernameAvailable ? 'success' : 'danger') : undefined}
            value={username}
            onChange={handleUsernameChange}
          />
          {username && (
            <Icon
              size="small"
              align="right"
              color={usernameAvailable ? 'success' : 'danger'}
            >
              <FontAwesomeIcon
                icon={usernameAvailable ? faCheck : faTriangleExclamation}
              />
            </Icon>
          )}
        </Control>
        {username && (
          <Help color={usernameAvailable ? 'success' : 'danger'}>
            {usernameMessage}
          </Help>
        )}
      </Field>
      <Field>
        <Label htmlFor="email">
          email
        </Label>
        <Control iconsRight>
          <Input
            id="email"
            placeholder="email"
            color={email ? (emailAvailable ? 'success' : 'danger') : undefined}
            value={email}
            onChange={handleEmailChange}
          />
          {email && (
            <Icon
              size="small"
              align="right"
              color={emailAvailable ? 'success' : 'danger'}
            >
              <FontAwesomeIcon
                icon={emailAvailable ? faCheck : faTriangleExclamation}
              />
            </Icon>
          )}
        </Control>
        {email && (
          <Help color={emailAvailable ? 'success' : 'danger'}>
            {emailMessage}
          </Help>
        )}
      </Field>
      <Field>
        <Label htmlFor="password">
          Password
        </Label>
        <Control iconsRight>
          <Input
            id="password"
            placeholder="Password"
            type="password"
            color={password ? (passwordValid ? 'success' : 'danger') : undefined}
            value={password}
            onChange={handlePasswordChange}
          />
          {password && (
            <Icon
              size="small"
              align="right"
              color={passwordValid ? 'success' : 'danger'}
            >
              <FontAwesomeIcon
                icon={passwordValid ? faCheck : faTriangleExclamation}
              />
            </Icon>
          )}
        </Control>
        {password && (
          <Help color={passwordValid ? 'success' : 'danger'}>
            {passwordMessage}
          </Help>
        )}
      </Field>
      <hr className="separator" />
      <div className="has-text-right">
        <Button color="success" onClick={register} disabled={!passwordValid || !usernameAvailable || !slotsAvailable}>
          {registerButton()}
        </Button>
      </div>
    </Box>
  );
}
